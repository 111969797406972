<template>
  <article
    class="ticket"
    v-if="ticket"
    :class="{ 'ticket--checkout': isCheckout }"
  >
    <div class="ticket__header">
      <div
        class="ticket__badge"
        :style="`background: ${getTicketBadgeColor()}`"
      >
        <img src="../../assets/img/raccoon.png" alt="raccoon" width="40" />
      </div>
      <div>
        <p class="ticket__title">{{ ticket.title }}</p>
        <p class="ticket__price">$ {{ ticket.price / 100 }}</p>
      </div>
    </div>

    <div class="ticket__description">
      <div>
        <div class="pb-5">{{ ticket.description }}</div>
        <dl :class="!isCheckout ? 'pb-4' : 'pb-8'">
          <dt>This pass includes:</dt>
          <dd
            v-for="feature in ticket.features"
            :key="feature.feature"
            class="ticket__feature"
          >
            {{ feature.feature }}
          </dd>
        </dl>
      </div>
      <Button
        v-if="!isCheckout"
        is-empty
        :disabled="ticket.sold_out"
        class="ticket__button"
        @click="$router.push(`/${$route.params.id}/checkout/${ticket.id}`)"
      >
        get ticket
      </Button>
      <div v-else>
        <h3 v-if="ticket.sold_out" class="mb-4" style="text-align: center">
          Sold out
        </h3>
        <InputNumber
          v-else
          v-model.number="quantity"
          :name="`${ticket.id}-quantity`"
          label="Quantity"
          @input="$emit('change-quantity', quantity)"
          @change="$emit('change-quantity', quantity)"
        />
      </div>
    </div>
  </article>
</template>

<script>
import Button from "@/components/common/Button";
import InputNumber from "@/components/common/InputNumber";
import { validate } from "@/assets/js/validation";

export default {
  name: "Ticket",
  emits: ["change-quantity"],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    defaultQuantity: {
      type: Boolean,
      default: false,
    },
  },
  components: { InputNumber, Button },
  data: () => ({
    quantity: 0,
  }),
  mounted() {
    if (this.defaultQuantity) this.quantity = 1;
  },
  methods: {
    validate,
    getTicketBadgeColor() {
      let price = this.ticket.price / 100;
      let badgeColor;
      switch (true) {
        case price <= 20: {
          badgeColor = "#535353;";
          break;
        }
        case price > 20 && price <= 40: {
          badgeColor = "#8d8d8d;";
          break;
        }
        case price > 40: {
          badgeColor = "#fff;";
          break;
        }
        default: {
          badgeColor = "#535353;";
          break;
        }
      }
      return badgeColor;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.ticket {
  $self: &;

  padding: 42px 40px 55px;
  border: 1px solid $border;
  border-radius: 10px;

  &__header {
    height: 20%;
    display: flex;
    justify-content: space-between;
    text-align: right;
    line-height: 32px;
    letter-spacing: 0.05em;
  }

  &__badge {
    text-align: left;
    padding: 3%;
    width: 130px;
    height: 55px;
    transform: translateX(-38%);
    border-radius: 10px;
  }

  &__title {
    font-weight: bold;
    color: $red;
  }

  &__description {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__feature {
    margin: 0;
    padding-left: 16px;
    position: relative;

    &::before {
      content: "\2013";
      position: absolute;
      left: 0;
    }
  }

  &__button {
    width: 100%;
  }

  @media (min-width: $media-sm) {
    &__badge {
      width: 64px;
      height: 64px;
      transform: translateY(-100%);
      text-align: center;
      padding: 5% 0 0 0;
    }
  }

  @media (min-width: $media-lg) {
    min-height: 650px;

    &--checkout {
      min-height: 700px;
    }
    &__badge {
      width: 69px;
    }
  }
}
</style>
