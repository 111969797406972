<template>
  <nav
    class="nav padding-x"
    :class="{
      'nav--opened': isMobNavOpened,
      'nav--sticky': isNavSticky,
    }"
  >
    <router-link to="/">
      <picture>
        <source media="(max-width: 719px)" :srcset="logoPath" />
        <source media="(min-width: 720px)" :srcset="logoPath" />
        <img :src="logoPath" alt="Exploit logo" class="nav__logo" />
      </picture>
    </router-link>
    <!--        mobile view-->
    <button
      class="nav__toggler"
      :class="{ active: isMobNavOpened }"
      @click="isMobNavOpened = !isMobNavOpened"
    >
      <span />
      <!-- for burger menu -->
    </button>
    <NavMenu v-show="isMobNavOpened" :menu="navLinks" :close="closeMenu" />
    <!--    desktop view-->
    <div class="nav__menu">
      <router-link
        v-for="item in navLinks"
        :key="item.name"
        :to="`#${item.path}`"
        class="nav__link"
      >
        {{ item.name }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "@/assets/js/utils";
import NavMenu from "./NavMenu";

export default {
  name: "Navigation",
  props: {},
  components: {
    NavMenu,
  },
  data: () => ({
    isMobNavOpened: false,
    isNavSticky: false,
    navLinks: [
      {
        name: "About",
        path: "about",
      },
      {
        name: "CFP",
        path: "apply",
      },
      // {
      //   name: "Schedule",
      //   path: "schedule",
      // },
      // {
      //   name: "Workshops",
      //   path: "workshops",
      // },
      // {
      //   name: "Villages",
      //   path: "villages",
      // },
      {
        name: "Tickets",
        path: "tickets",
      },
      {
        name: "Contact us",
        path: "contacts",
      },
    ],
  }),
  computed: {
    ...mapState(["currentEventID"]),
    logoPath() {
      return require("@/assets/img/logo.png");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.debouncedChecker());
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.debouncedChecker());
  },
  methods: {
    ...mapActions(["signOut"]),
    debounce,
    detectIsAboutSectionVisible() {
      const screenHeight = window.screen.availHeight;
      const scrolledPixels = Math.abs(
        document.getElementById("container").getBoundingClientRect().top
      );
      const isFirstScreenScrolledOut = scrolledPixels >= screenHeight - 300;
      this.isNavSticky = isFirstScreenScrolledOut || this.isMobNavOpened;
    },
    debouncedChecker() {
      return this.debounce(this.detectIsAboutSectionVisible, 200);
    },
    closeMenu() {
      this.isMobNavOpened = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.nav {
  $self: &;

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--sticky {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
  }

  &--opened,
  &--not-home {
    #{$self}__toggler {
      &::before,
      &::after,
      span {
        background-color: $white;
      }
    }
  }

  &__logo {
    width: 137px;
  }

  &__menu {
    display: none;
  }

  &__toggler {
    border: none;
    background: none;
    padding: 0;
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 5;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &::before,
    &::after {
      content: "";
    }

    &::before,
    &::after,
    span {
      position: absolute;
      left: 0;
      height: 2px;
      width: 24px;
      border-radius: 1px;
      background-color: $white;
      transition: 0.3s ease-in-out;
    }

    &::before {
      top: 3px;
    }

    span {
      top: 11px;
    }

    &::after {
      top: 19px;
    }

    &.active {
      &::before {
        transform: rotate(45deg);
        top: 9px;
        left: 0;
      }

      span {
        width: 0;
        opacity: 0;
      }

      &::after {
        transform: rotate(-45deg);
        top: 9px;
        left: 0;
      }
    }
  }

  &__link {
    text-decoration: none;
    margin-right: 60px;

    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      color: $red;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 24px;
    padding-bottom: 24px;

    &--opened {
      background-color: transparent;
    }
  }

  @media (min-width: $media-md) {
    &__toggler {
      display: none;
    }
    &__menu {
      display: flex;
    }
  }

  @media (min-width: $media-lg) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
</style>
