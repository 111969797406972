<template>
  <div>
    <Preloader :loading="isSponsorsLoading" />
    <section
      v-if="sponsors.gold && sponsors.gold.length"
      class="sponsors padding-x"
    >
      <Heading type="h2" text-align="center" class="sponsors__heading">
        Gold Sponsors
      </Heading>
      <div class="sponsors__list">
        <a
          v-for="sponsor in sponsors.gold"
          :key="sponsor.id"
          :href="sponsor.website"
          class="sponsors__item"
        >
          <img :src="sponsor.logo" alt="sponsor logo" class="sponsors__img" />
        </a>
      </div>
    </section>
    <section
      v-if="sponsors.silver && sponsors.silver.length"
      class="sponsors padding-x"
    >
      <Heading type="h2" text-align="center" class="sponsors__heading">
        Silver Sponsors
      </Heading>
      <div class="sponsors__list">
        <a
          v-for="sponsor in sponsors.silver"
          :key="sponsor.id"
          :href="sponsor.website"
          class="sponsors__item"
        >
          <img :src="sponsor.logo" alt="sponsor logo" class="sponsors__img" />
        </a>
      </div>
    </section>
    <!-- <ComingSoon v-else entity="Sponsors" class="mt-16" /> -->
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
// import ComingSoon from "@/components/common/ComingSoon";
import { mapActions, mapState } from "vuex";

export default {
  name: "Sponsors",
  props: {},
  components: {
    Preloader,
    Heading,
    // ComingSoon,
  },
  data: () => ({
    isSponsorsLoading: false,
    noSponsors: false,
    sponsors: {
      gold: null,
      silver: null,
    },
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    this.isSponsorsLoading = true;
    this.getSponsors();
  },
  methods: {
    ...mapActions(["GET"]),
    getSponsors() {
      return this.GET({
        route: `public/event/${this.currentEventID}/sponsorship`,
      })
        .then(({ data }) => {
          this.isSponsorsLoading = false;
          if (data) {
            this.sponsors.gold = data.filter(
              (sponsor) => sponsor.level === "gold"
            );
            this.sponsors.silver = data.filter(
              (sponsor) => sponsor.level === "silver"
            );
          } else {
            this.noSponsors = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isSponsorsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.sponsors {
  $self: &;

  padding-top: 36px;

  &__heading {
    margin-bottom: 20px;
  }

  &__list {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    width: 280px;
    height: 280px;
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    max-width: 215px;
    margin-right: 16px;
    margin-bottom: 20px;
    /*&:last-of-type {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  &__img {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  @media (min-width: $media-xs) {
    &__item {
      width: 215px;
      height: 215px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 120px;

    &__heading {
      margin-bottom: 56px;
    }

    &__item {
      width: 100%;
      margin-right: 24px;
    }
  }

  @media (min-width: $media-lg) {
    &__heading {
      margin-bottom: 88px;
    }
  }
}
</style>
