<template>
  <section v-if="events.length" class="padding-x">
    <Heading type="h2" class="mt-12">UPCOMING EVENTS</Heading>
    <div class="events">
      <EventCard
        v-for="event in eventsResult"
        :key="event.id"
        :data="event"
        @click="$router.push(`/${event.id}`)"
      />
    </div>
    <Button
      v-show="events.length > 6"
      class="events-button"
      outlined
      @click="showAllEvents = !showAllEvents"
    >
      {{ showAllEvents ? "show less" : "show more" }}
    </Button>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Heading from "@/components/common/Heading";
import Button from "@/components/common/Button";
import EventCard from "@/components/common/EventCard";

export default {
  name: "UpcomingEvents",
  props: {},
  components: {
    EventCard,
    Button,
    Heading,
  },
  data: () => ({
    showAllEvents: false,
  }),
  computed: {
    ...mapGetters({
      events: "getUpcomingEvents",
    }),
    ...mapState(["currentEventID"]),
    trimmedEvents() {
      return this.events.slice(0, 6);
    },
    eventsResult() {
      return this.showAllEvents ? this.events : this.trimmedEvents;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.events-button {
  margin: 20px auto 0;
}

.events {
  padding-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: $media-sm) {
    padding-top: 72px;
    justify-content: center;
  }

  @media (min-width: $media-lg) {
    margin-bottom: 50px;
  }

  @media (min-width: 1800px) {
    justify-content: space-between;
  }
}
</style>
