<template>
  <SectionWithBg heading-position="right">
    <template #heading>
      What is
      <br />
      {{ event.name ? event.name : "ExploitCon" }}?
    </template>
    <template #body>
      <div v-if="event && event.description">{{ event.description }}</div>
      <div v-else>
        ExploitCon is a national conference that focuses on providing attendees
        with technical information from industry experts in order to educate the
        IT community on advancements in Cyber Security and Information
        Technology. Enjoy presentations from local speakers on a wide range of
        IT topics, connect with peers and other industry experts, or get hands
        on experience at any of our villages. At Exploit we work hard to provide
        our attendees with the latest and greatest in Cyber Security and our
        main goal is to make sure you walk away more educated.
      </div>
    </template>
  </SectionWithBg>
</template>

<script>
import SectionWithBg from "@/components/common/SectionWithBg";

export default {
  name: "About",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionWithBg,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
