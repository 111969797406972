<template>
  <div class="container" id="container">
    <Header v-if="$route.name !== 'Event'" />
    <Nav v-else />
    <slot />
    <Footer />
  </div>
</template>

<script>
import Header from "./Header";
import Nav from "./NavHeader";
import Footer from "./Footer";

export default {
  name: "Container",
  props: {},
  components: {
    Header,
    Nav,
    Footer,
  },
  data: () => ({}),
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.container {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}
</style>
