<template>
  <div class="d-flex">
    <div class="slot" :class="{ keynote: data.submission.is_keynote }">
      <div v-if="data.submission.is_keynote" class="badge">Keynote</div>
      <img
        :src="getTrackIcon(data.track)"
        :alt="data.track"
        class="slot__track"
      />
      <Splide :options="splideOptions">
        <SplideSlide
          v-for="addSpeaker in overallSpeaker"
          :key="addSpeaker.id"
          :class="{ keynote: addSpeaker.is_keynote }"
        >
          <div class="d-flex speaker-container">
            <img
              :src="speakerImage(addSpeaker.image)"
              :alt="`${data.title} speaker photo`"
              class="slot__image"
              :class="{ 'first-img': data.submission?.add_bios.length }"
            />
            <div class="slot__speaker">
              <div class="slot__speaker-name">
                {{ addSpeaker.first_name }}
                {{ addSpeaker.last_name }}
              </div>
              <div>{{ addSpeaker.organization }}</div>
            </div>
          </div>
        </SplideSlide>
      </Splide>

      <div class="slot__content">
        <div class="d-flex" style="justify-content: space-between">
          <div class="slot__content-title">
            {{ shortenText(data.title, 35) }}
          </div>
          <img
            :src="calendarIconSrc"
            alt="calendar"
            @mouseover="calendarHovered = true"
            @mouseleave="calendarHovered = false"
            @click.stop="downloadEventToCalendar"
            class="slot__calendar"
          />
        </div>

        <div class="slot__content-description">
          {{ shortenText(data.description, 60) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// eslint-disable-next-line
  const cal = ics()
import { shortenText } from "@/assets/js/utils";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  name: "slot-card",
  props: ["data"],
  components: {
    Splide,
    SplideSlide,
  },
  data: () => ({
    calendarHovered: false,
  }),
  computed: {
    calendarIconSrc() {
      return this.calendarHovered
        ? require("@/assets/img/icons/calendar-plus-red.svg")
        : require("@/assets/img/icons/calendar-plus.svg");
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL.split("api/")[0];
    },
    overallSpeaker() {
      if (this.data.submission && this.data.submission.add_bios) {
        console.log({ data: this.data });
        return [this.data.submission.bio, ...this.data.submission.add_bios];
      }

      return [];
    },
    splideOptions() {
      return {
        direction: "ttb",
        interval: "2500",
        type: "loop",
        wheel: true,
        autoplay: true,
        pause: false,
        height: "320px",
        arrows: false,
      };
    },
  },
  methods: {
    shortenText,
    speakerImage(img) {
      return img ? img : require("@/assets/img/empty-speaker.jpg");
    },
    getTrackIcon(track) {
      if (
        track === "build_it" ||
        track === "break_it" ||
        track === "firetalks" ||
        track === "show_it"
      ) {
        return require(`@/assets/img/icons/${track}.svg`);
      } else {
        return require("@/assets/img/icons/tracks.svg");
      }
    },
    downloadEventToCalendar: function () {
      const start = this.convertTime(this.data.start_time);
      const end = this.convertTime(this.data.end_time);
      const route =
        this.baseUrl +
        "#/" +
        this.$route.params.id +
        "/speaker/" +
        this.data.submission.id;
      cal.addEvent(this.data.title, this.data.description, route, start, end);
      cal.download(this.data.title);
    },
    convertTime: function (time) {
      const utcTime = moment.utc(time).toDate();
      const localTime = moment(utcTime).local().format("YYYY-MM-DDTHH:mm");
      const localTimeFormatted = moment(localTime, "YYYY-MM-DDTHH:mm").toDate();
      return moment(localTimeFormatted)
        .locale("en")
        .format("MM/DD/YYYY hh:mm a");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.keynote {
  border: 1px solid $red;
}

.badge {
  position: absolute;
  left: 0;
  top: 0;
  background-color: $red;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 10px;
  z-index: 1;
}

.slot {
  cursor: pointer;
  position: relative;
  width: min-content;
  background: $gray;
  border-radius: 10px;
  margin-right: 32px;
  margin-bottom: 32px;

  &__track {
    position: absolute;
    top: 10px;
    right: 5px;
    padding: 5px;
    background: linear-gradient(160deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
    border-radius: 10px;
    width: 40px;
    z-index: 1;
  }

  &__image {
    border-radius: 10px;
    width: 320px;
    height: 320px;
    object-fit: cover;
    object-position: center;
    display: block;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  &__speaker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: +9999;
    letter-spacing: 0.05em;
    line-height: 32px;
    color: $border;
    font-weight: bold;

    &-name {
      color: $white;
      font-family: $Oswald;
      text-transform: uppercase;
      font-size: 24px;
    }
  }

  &__content {
    padding: 14px 24px;

    &-title {
      font-weight: bold;
      padding-bottom: 8px;
    }

    &-description {
      opacity: 0.6;
    }
  }

  &__calendar {
    cursor: pointer;
  }
}

.first-img {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.second-img {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

::v-deep .splide {
  max-height: 320px;
  overflow: hidden;
  border-radius: 10px !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  position: relative !important;

  ul {
    padding-inline-start: 0 !important;
    position: relative;
    height: 320px;
  }

  &__list {
    padding: 0 !important;
    li {
      list-style: none;
      position: relative;
      height: 320px;
    }
  }
}

.speaker-container {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
    background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
  }
}
</style>
``
