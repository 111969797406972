<template>
  <div class="coming">
    <div class="coming__legend">
      <img
        src="@/assets/img/logo.png"
        alt="Exploit logo"
        class="coming__legend-logo"
      />
      <div v-if="text && text.length" class="coming__legend-title">
        {{ text }}
      </div>
      <div v-else class="coming__legend-title">
        {{ entity }}
        {{ entity.endsWith("s") ? "are" : "is" }}
        coming soon
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  props: {
    entity: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.coming {
  $self: &;

  position: relative;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 24px;
    width: 18%;
    background-color: $red;
  }

  &::after {
    bottom: 0;
    left: 0;
  }

  &::before {
    top: 0;
    right: 0;
  }

  &__legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30%;

    &-logo {
      width: 250px;
    }

    &-title {
      font-family: $sarabun;
      font-weight: $sarabunBold;
      font-size: 1.5rem;
      letter-spacing: 0.08em;
      color: $white;
      margin-top: 10%;
      text-align: center;
    }
  }

  @media (min-width: $media-md) {
    &::before,
    &::after {
      width: 48%;
    }
    &__legend {
      &-title {
        font-size: 40px;
        line-height: 56px;
      }

      &-logo {
        width: 600px;
      }
    }
  }
}
</style>
