<template>
  <nav class="nav padding-x">
    <router-link
      to="/"
      v-if="!['Exhibitor kit', 'Speaker Kit'].includes($route.name)"
    >
      <img :src="logoPath" alt="Exploit logo" class="nav__logo" />
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data: () => ({}),
  computed: {
    logoPath() {
      return require("@/assets/img/logo.png");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.nav {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    width: 137px;
  }

  @media (min-width: $media-sm) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @media (min-width: $media-lg) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
</style>
