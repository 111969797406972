<template>
  <div class="event-card">
    <div class="event-card__date">{{ getTime(data.start) }}</div>
    <img
      :src="imageSrc"
      :alt="`${data.name} image`"
      class="event-card__image"
    />
    <div class="event-card__content">
      <div>
        <Heading noDecoration type="h4">
          {{ data.name }}
        </Heading>
        <div
          class="mt-3 event-card__description"
          v-html="shortenText(data.description, 64)"
        />
      </div>
      <div class="event-card__timer">
        <div class="event-card__timer-title">EVENT COMING IN</div>
        <Timer :start-time="data.start" />
      </div>
    </div>
  </div>
</template>

<script>
import { shortenText } from "@/assets/js/utils";
import Heading from "@/components/common/Heading";
import Timer from "@/components/common/Timer";

export default {
  name: "EventCard",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Timer,
    Heading,
  },
  data: () => ({}),
  computed: {
    imageSrc() {
      return this.data.background
        ? this.data.background
        : require("@/assets/img/event-sample.svg");
    },
  },
  methods: {
    shortenText,
    getTime(dateString) {
      const startDate = new Date(dateString);
      return startDate.toLocaleString("en-US", {
        timeZone: this.data.time_zone,
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.event-card {
  cursor: pointer;
  position: relative;
  width: 402px;
  background: $gray;
  border-radius: 10px;
  margin-right: 32px;
  margin-bottom: 32px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 402px;
    height: 320px;
    background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
  }

  &__date {
    position: absolute;
    top: 16px;
    left: 24px;
    padding: 12px 24px;
    background-color: $red;
    border-radius: 10px;
    z-index: 2;
    font-size: 16px;
  }

  &__image {
    border-radius: 10px;
    width: 100%;
    height: 320px;
    object-fit: cover;
    object-position: center;
    display: block;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  &__content {
    height: 392px;
    padding: 48px 15px 32px 24px;
    letter-spacing: 0.05em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__description {
    line-height: 32px;
  }

  &__timer-title {
    font-family: $Oswald;
    font-size: 16px;
    color: $red;
  }
}
</style>
