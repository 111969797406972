<template>
  <div ref="inputContainerRef" class="input input-number">
    <label class="input-number__label">
      {{ label }}
      <!--      <button
        class="input-number__btn minus"
        type="button"
        onclick="this.parentNode.querySelector('[type=number]').stepDown();"
      >
        -
      </button>-->
      <input
        type="number"
        min="0"
        :value.prop="modelValue"
        :placeholder="label"
        :id="name"
        ref="inputRef"
        class="input__control input-number__field"
        v-bind="$attrs"
        @input="$emit('update:modelValue', +$event.target.value)"
      />
      <!--      <button
        class="input-number__btn plus"
        type="button"
        onclick="this.parentNode.querySelector('[type=number]').stepUp();"
      >
        +
      </button>-->
    </label>
    <transition name="fade">
      <div v-if="error" class="input__error">
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "InputNumber",

  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    // autoResize() {
    //   if (this.isMultiline) {
    //     const elem = document.getElementById(this.name);
    //     const offset = elem.offsetHeight - elem.clientHeight;
    //
    //     elem.style.height = this.modelValue
    //             ? elem.scrollHeight + offset + "px"
    //             : "56px";
    //   }
    // },
    incrementDecrementNumber(action) {
      switch (action) {
        case "+":
          this.$refs["input"].stepUp();
          break;
        case "-":
          this.$refs["input"].stepDown();
          break;
      }
      /* Need to manually $emit here since the above methods doesn't trigger our computed set method. */
      this.$emit("input", this.$refs["input"].value);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.input-number {
  text-align: center;

  &__label {
    font-family: $sarabun;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: $white;
    text-align: center;
  }
  &__field {
    text-align: center;
  }
  &__btn {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    font-size: 18px;
  }
  &__btn.minus {
    left: 0;
    top: 45%;
    font-weight: $sansBold;
  }
  &__btn.plus {
    right: 0;
    top: 45%;
    font-weight: $sarabunReg;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
