<template>
  <div class="upcoming-workshops">
    <div class="workshop" v-for="(workshop, index) in workshops" :key="index">
      <div class="workshop__image-wrapper">
        <img :src="workshop.image" alt="" />
        <span class="overlay"></span>
      </div>

      <div class="workshop__content-wrapper">
        <Heading type="h4" class="workshop__title">
          {{ workshop.title }}

          <!-- <br />
          WORKSHOP -->
        </Heading>

        <p>
          ExploitCon workshops cover range of technical topics and responses to
          emerging threats. You will get hands-on experience by working with
          cyber security experts and industry leaders.
        </p>
        <p>
          We usually announce workshop topics a few weeks before the event and
          typically hold them the day before event.
        </p>
        <p>
          If you are interested to learn more, sign up with your email and we
          will notify you once the workshop registration opens.
        </p>

        <form @submit.prevent="submit" v-if="!success">
          <div class="workshop__form-wrapper">
            <Input
              v-model="form.email.value"
              :error="form.email.error"
              :disabled="isFormSending"
              :name="name"
              :label="form.email.label"
              type="email"
              @input="clearError('email', form)"
              @blur="validateField('email', form)"
            />
            <Button :loading="emailLoading">Submit</Button>
          </div>
        </form>
        <Heading type="h5" v-else>
          Thank you for your interest in ExploitCon Workshops! We'll send you an
          update as soon as available.
        </Heading>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import { mapActions } from "vuex";
import { transformForm } from "@/assets/js/utils";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
export default {
  components: {
    Heading,
    Input,
    Button,
  },
  props: {
    eventId: {
      type: String,
    },
    workshops: {
      type: Array,
      default: () => [
        {
          title: "Workshops are coming soon",
          image: require("@/assets/img/upcoming/workshop-1.png"),
        },
      ],
    },
  },
  data() {
    return {
      emailLoading: false,
      success: false,
      form: {
        email: {
          value: "",
          error: "",
          rules: ["required", "email"],
          label: "Email",
        },
        form_type: {
          value: "workshop",
          rules: [],
        },
        event_id: {
          value: this.eventId,
          rules: [],
        },
      },
    };
  },
  methods: {
    ...mapActions(["submitEmailForWorkshop"]),
    transformForm,
    validateField,
    validateForm,
    clearError,
    submit() {
      const isValid = this.validateForm(this.form);
      if (!isValid) return;
      this.emailLoading = true;
      this.submitEmailForWorkshop(this.transformForm(this.form))
        .then(() => {
          this.emailLoading = false;
          this.success = true;
          this.form.email.value = "";
        })
        .catch(() => {
          this.success = false;
          this.emailLoading = false;
          this.form.email.value = "";
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.upcoming-workshops {
  .workshop {
    display: flex;
    flex-direction: column;
    position: inherit;
    width: 100%;
    background-color: $gray;
    min-height: 425px;
    border-radius: 10px;

    &__image-wrapper {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        position: relative;
      }
      .overlay {
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(21, 21, 21, 0) 0%,
          #151515 100%
        );
        border-radius: 10px;
        position: absolute;
        z-index: 1;
      }
    }

    &__content-wrapper {
      padding: 16px;

      h4 {
        font-size: 24px;
        text-align: right;
        letter-spacing: 0.05em;
        margin-bottom: 16px;
        text-align: left;
        text-transform: uppercase;
      }

      h5 {
        font-size: 18px;
        text-align: left;
        line-height: 1em;
        margin-top: 32px;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff80;
        letter-spacing: 0.05em;
      }
    }

    &__form-wrapper {
      margin-top: 16px;

      button {
        margin-left: auto;
      }
    }

    &:not(:first-of-type) {
      margin-top: 32px;
    }
  }

  @media (min-width: $media-sm) {
    .workshop {
      &__image-wrapper {
        img {
          width: 100%;
        }
      }

      &__content-wrapper {
        padding: 24px;

        h4 {
          font-size: 28;
        }

        h5 {
          font-size: 24px;
        }

        p {
          font-size: 16px;
        }
      }

      &__form-wrapper {
        display: flex;
        column-gap: 16px;
        margin-top: 32px;
      }
    }
  }

  @media (min-width: $media-md) {
    .workshop {
      display: flex;
      flex-direction: row;
      position: inherit;
      width: 100%;
      background-color: $gray;
      min-height: 425px;
      border-radius: 10px;
      &__image-wrapper {
        flex-basis: 50%;
        position: relative;

        img {
          width: 100%;
        }
      }

      &__content-wrapper {
        flex-basis: 50%;
        padding: 32px;

        h4 {
          font-size: 36px;
          text-align: right;
          letter-spacing: 0.05em;
          margin-bottom: 16px;
        }

        h5 {
          font-size: 32px;
        }

        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 32px;
          color: #ffffff80;
          letter-spacing: 0.05em;
          text-align: right;
        }
      }

      &__form-wrapper {
        display: flex;
        column-gap: 16px;
        margin-top: 32px;
      }

      &:nth-of-type(even) {
        .workshop__image-wrapper {
          order: 2;
        }
        .workshop__content-wrapper {
          h4,
          p {
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
