<template>
  <h6
    class="mb-1 card-session-timer"
    :class="{ soon: talkStarted === false && time <= 30000, live: talkStarted }"
  >
    {{ scheduleTime }}
  </h6>
</template>

<script>
import moment from "moment";

export default {
  name: "Timer",
  props: {
    startTime: {
      type: String,
      default: "",
    },
  },
  created() {
    let now = new Date().getTime();
    now = moment(now);
    if (this.talkStarted) {
      this.time = now.valueOf() - moment.utc(this.startTime).valueOf();
      this.day = now.diff(moment.utc(this.startTime, "days"));
    } else {
      this.time = moment.utc(this.startTime).valueOf() - now.valueOf();
      this.day = moment.utc(this.startTime).diff(now, "days");
    }
    setInterval(() => {
      if (this.talkStarted) {
        this.time = this.time + 1000;
      } else {
        this.time = this.time - 1000;
      }
    }, 1000);
  },
  computed: {
    scheduleTime: function () {
      let result = "";
      if (this.time) {
        result = moment.utc(this.time).format("HH : mm : ss");
      }
      return `${this.day || 0} : ${result}`;
    },
    talkStarted: function () {
      let started = false;
      const now = new Date().getTime().valueOf();
      const start = moment.utc(this.startTime).valueOf();
      if (start <= now) {
        started = true;
      }
      return started;
    },
  },
  data() {
    return {
      time: null,
      day: null,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.card-session-timer {
  font-family: $Oswald;
  font-size: 36px;
  letter-spacing: 0.05em;
  line-height: 53px;
}

.soon.card-session-timer {
  color: $red;
}

.live.card-session-timer {
  color: $red;
}
</style>
