<template>
  <Preloader
    v-if="isEventLoading"
    :loading="isEventLoading"
    class="loader"
    style="height: 100vh"
  />
  <div v-else class="home-page">
    <Hero :event="event" id="home" />
    <StatisticsBlock :loading="isEventLoading" :statistic="event.event_stats" />
    <About id="about" :event="event" />
    <ScheduleSpeakersListVariant />
    <Schedule id="schedule" />
    <Apply id="apply" />
    <Workshops id="workshops" />
    <Villages id="villages" />
    <Tickets id="tickets" :start="event.start" />
    <Volunteering id="volunteering" />
    <Sponsors id="sponsors" />
    <!-- <SponsorAcquire /> -->
    <!-- <Blog /> -->
    <ContactUs id="contacts" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Preloader from "@/components/common/Preloader";
import Hero from "@/components/event/Hero";
import About from "@/components/home/About";
// import SponsorAcquire from "@/components/home/SponsorAcquire";
import Apply from "@/components/home/Apply";
import Schedule from "@/components/event/Schedule/Schedule";
import ScheduleSpeakersListVariant from "@/components/event/Schedule/SpeakersListVariant";
import Workshops from "@/components/event/Workshops";
import Villages from "@/components/event/Villages";
import Volunteering from "@/components/event/Volunteering";
import Sponsors from "@/components/event/Sponsors";
import Tickets from "@/components/event/Tickets";
import ContactUs from "@/components/event/ContactUs";
import StatisticsBlock from "@/components/event/StatisticsBlock";
// import Blog from "@/components/event/Blog";
import Cookie from "js-cookie";

export default {
  name: "Event",
  components: {
    Preloader,
    Workshops,
    StatisticsBlock,
    Hero,
    About,
    // SponsorAcquire,
    Apply,
    Schedule,
    ScheduleSpeakersListVariant,
    Villages,
    Volunteering,
    Sponsors,
    Tickets,
    ContactUs,
    // Blog,
  },
  data() {
    return {
      event: {},
      isEventLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      savedEvent: "getCurrentEventDetails",
    }),
    ...mapState(["currentEventID", "auth/userName"]),
  },
  watch: {
    "$route.params": {
      handler(route) {
        console.log({ route });
        if (route.id.includes("access_token=")) {
          this.$router.push(
            `/${Cookie.get("event_id")}/ticket/${Cookie.get(
              "event_ticket_token"
            )}`
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (
      Object.keys(this.savedEvent).length &&
      this.$route.params.id === this.savedEvent.id
    ) {
      this.event = this.savedEvent;
    } else {
      this.isEventLoading = true;
      this.getCurrentEvent();
    }
  },
  methods: {
    ...mapActions(["getEvent", "GET", "POST"]),
    getCurrentEvent() {
      return this.getEvent(this.$route.params.id)
        .then((resp) => {
          this.event = resp;
          this.isEventLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isEventLoading = false;
        });
    },
  },
};
</script>
