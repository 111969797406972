<template>
  <div class="hero padding-x" :style="`background-image: url(${mainImage});`">
    <Preloader
      v-if="loading"
      :loading="loading"
      size="50px"
      class="hero__loader"
    />
    <div v-show="!loading" class="hero__wrapper">
      <div class="hero__info">
        <div>
          <Heading type="h1" class="mt-2 hero__heading">
            {{ event?.name }}
          </Heading>
          <!--          <p class="hero__text">
            {{ event?.description }}
          </p>-->
        </div>

        <div class="hero__box-wrapper">
          <div class="hero__box">
            <h5 class="hero__box-heading">LOCATION</h5>
            <div class="hero__box-text">
              {{ event?.venue?.name }}
              <div>{{ event?.venue?.address }}</div>
            </div>
          </div>
          <div class="hero__box">
            <h5 class="hero__box-heading">Date</h5>
            <div class="hero__box-text">
              {{ confDay }}
              <div>{{ confTime }} {{ event?.tz_abbrev }}</div>
            </div>
          </div>
        </div>
        <Button
          type="text"
          class="hero__button"
          @click="$router.push(`/${$route.params.id}/join`)"
        >
          Register
        </Button>
        <!-- <router-link style="text-decoration: none" to="#about">
          <Button class="hero__button" type="text"> Explore</Button>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Button from "@/components/common/Button";
import Preloader from "@/components/common/Preloader";

export default {
  name: "Hero",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Preloader,
    Heading,
    Button,
  },
  data: () => ({
    timeLeft: null,
    timeLeftWord: "days",
  }),
  computed: {
    mainImage() {
      return this.event.background
        ? this.event.background
        : require("@/assets/img/main_background.png");
    },
    startTimeHours() {
      return this.getTime(this.event.start, "time");
    },
    endTimeHours() {
      return this.getTime(this.event.end, "time");
    },
    confDay() {
      return this.getTime(this.event.start, "day");
    },
    confTime() {
      return this.event?.start && this.event?.end
        ? `${this.startTimeHours} - ${this.endTimeHours}`
        : "";
    },
  },
  methods: {
    getTime(dateString, modifyer) {
      const startDate = new Date(dateString);
      const options =
        modifyer === "time"
          ? {
              timeZone: this.event.time_zone,
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          : {
              timeZone: this.event.time_zone,
              month: "short",
              day: "2-digit",
              year: "numeric",
            };
      console.log({ startDate });
      return startDate.toLocaleString("en-US", options);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.hero {
  $self: &;

  height: 100vh;
  position: relative;
  padding-top: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  /*  -webkit-filter: grayscale(100%); !* Safari 6.0 - 9.0 *!
        filter: grayscale(100%);*/
  overflow: hidden;
  padding-bottom: 5%;

  /*  &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
        }*/

  &__loader {
    margin-top: 50%;
  }

  &__wrapper {
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__heading {
    position: relative;
    overflow-wrap: break-word;
    font-size: 40px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  &__text {
    font-size: 0.7em;
    text-align: center;
  }

  &__box-wrapper {
    display: flex;
  }

  &__box {
    background-color: rgba(0, 0, 0, 0.5);
    height: 204px;
    padding: 24px 10% 24px 24px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;

    &:first-of-type {
      margin-right: 32px;
    }

    &-heading {
      font-family: $Oswald;
      font-size: 18px;
      opacity: 0.5;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    &-text {
      font-family: $Oswald;
      font-size: 18px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      div {
        margin-top: 12px;
        font-family: $Lato;
        font-size: 0.5em;
        line-height: 24px;
        letter-spacing: 0.05em;
        opacity: 0.5;
      }
    }
  }

  @media (min-width: $media-xs) {
    padding-top: 95px;

    &__text {
      font-size: 1em;
      line-height: 32px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 138px;

    &__wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }

    &__heading {
      text-align: left;
    }

    &__info {
      max-width: 323px;
    }

    &__text {
      text-align: left;
    }

    &__box-heading {
      font-size: 24px;
      line-height: 24px;
    }
    &__box-text {
      white-space: unset;
      overflow: unset;
    }
  }

  @media (min-width: $media-md) {
    &__loader {
      margin-top: 15%;
    }

    &__heading {
      font-size: 3em;
      line-height: 107px;
    }

    &__info {
      max-width: 385px;
    }
  }

  @media (min-width: $media-lg) {
    &__info {
      max-width: 602px;
    }
    &__box-heading {
      font-size: 26px;
      line-height: 26px;
    }
    &__box-text {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
</style>
