<template>
  <div class="schedule" v-if="Object.keys(slots).length">
    <div class="schedule__timeline">
      <div
        v-for="time in Object.keys(slots)"
        :key="time + 'time'"
        @click.prevent="setActiveTab(time)"
        class="schedule__timeline-item"
        :class="{ active: isTabActive(time) }"
      >
        {{ getTime(time) }}
      </div>
    </div>
    <div v-if="activeTab" class="d-flex" style="flex-wrap: wrap">
      <div
        v-for="talk in slots[activeTab]"
        :key="talk.id"
        @click="
          $router.push(`/${$route.params.id}/speaker/${talk.submission.id}`)
        "
      >
        <SlotCard :data="talk" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SlotCard from "./SlotCard";

export default {
  name: "ScheduleTable",
  props: ["slots"],
  components: { SlotCard },
  data: () => ({
    activeTab: "",
  }),
  watch: {
    slots: {
      handler(value) {
        if (Object.keys(value).length) {
          this.activeTab = Object.keys(value)[0];
        }
      },
      immediate: true,
    },
  },
  methods: {
    getTime(date) {
      return moment(date).format("MM.DD.YYYY hh:mm A");
    },
    isTabActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.schedule {
  padding: 0 40px;
  display: flex;

  &__timeline {
    margin-right: 24px;

    .active {
      background-color: $red;
    }

    &-item {
      cursor: pointer;
      padding: 12px 24px;
      border: 1px solid $border;
      border-radius: 10px;
      margin-bottom: 32px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  @media (max-width: $media-md) {
    overflow-x: scroll;
  }

  @media (min-width: $media-md) {
    padding: 0 160px;
    &__timeline {
      margin-right: 115px;
    }
  }
}
</style>
