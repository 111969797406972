<template>
  <div>
    <Preloader :loading="isScheduleLoading" />
    <!-- <ComingSoon v-if="isScheduleEmpty" entity="Schedule" class="mt-16" /> -->
    <div v-if="!isScheduleLoading" class="schedule">
      <Heading
        v-show="slots && Object.keys(slots).length"
        noDecoration
        text-align="center"
        class="schedule__heading"
      >
        Schedule
      </Heading>
      <ScheduleTable :slots="slots" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
// import ComingSoon from "@/components/common/ComingSoon";
import ScheduleTable from "./ScheduleTable";

export default {
  name: "Schedule",
  props: {},
  components: {
    ScheduleTable,
    // ComingSoon,
    Preloader,
    Heading,
  },
  data: () => ({
    isScheduleLoading: false,
    slots: {},
    isScheduleEmpty: false,
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  created() {
    this.getSlots();
  },
  methods: {
    ...mapActions(["GET"]),
    getSlots() {
      this.isScheduleLoading = true;
      return this.GET({
        route: `/public/event/${this.currentEventID}/schedule_sessions`,
      })
        .then((resp) => {
          console.log(resp);
          if (resp.data) {
            this.slots = resp.data;
          } else {
            this.isScheduleEmpty = true;
          }
          this.isScheduleLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isScheduleLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.schedule {
  &__heading {
    padding-top: 128px;
    margin-bottom: 30px;
  }

  @media (min-width: $media-sm) {
    &__heading {
      margin-bottom: 56px;
    }
  }
  @media (min-width: $media-sm) {
    &__heading {
      margin-bottom: 87px;
    }
  }
}
</style>
