import axios from "axios";

const getDefaultState = () => {
  return {
    currentEvent: {},
    nextEvent: {},
    upcomingEvents: [],
  };
};

const state = getDefaultState();

const getters = {
  getCurrentEventDetails: (state) => state.currentEvent,
  getNextEventDetails: (state) => state.nextEvent,
  getUpcomingEvents: (state) => state.upcomingEvents,
};

const mutations = {
  setCurrentEventDetails(state, payload) {
    state.currentEvent = payload;
  },
  setNextEventDetails(state, payload) {
    state.nextEvent = payload;
  },
  setUpcomingEvents(state, payload) {
    state.upcomingEvents = payload;
  },
  resetEventState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  getEvent: function ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/public/event/${id}`)
        .then(({ data }) => {
          commit("setCurrentEventDetails", data.data);
          commit("setCurrentEventID", data.data.id);
          resolve(data.data);
        })
        .catch((err) => {
          commit("resetEventState");
          reject(err);
        });
    });
  },

  submitEmailForWorkshop({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/form/workshop`, payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          commit("resetEventState");
          reject(err);
        });
    });
  },
};

export default { state, getters, mutations, actions };
