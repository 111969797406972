<template>
  <div class="home-page">
    <MainBlock :loading="isEventLoading" :event="event" id="home" />
    <About id="about" />
    <UpcomingEvents />
    <!--    <Blog />-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import MainBlock from "@/components/home/MainBlock";
import About from "@/components/home/About";
import UpcomingEvents from "@/components/home/UpcomingEvents";
// import Blog from "@/components/home/Blog";

export default {
  name: "Home",
  components: {
    UpcomingEvents,
    MainBlock,
    About,
    // Blog,
  },
  data() {
    return {
      event: {},
      isEventLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      nextEvent: "getNextEventDetails",
    }),
    ...mapState(["currentEventID"]),
  },
  mounted() {
    if (Object.keys(this.nextEvent).length) {
      this.event = this.nextEvent;
      this.$store.commit("setCurrentEventID", this.nextEvent.id);
    } else {
      this.isEventLoading = true;
      this.getCurrentEvent();
    }
  },
  methods: {
    ...mapActions(["getEvent", "GET", "POST"]),
    getCurrentEvent() {
      return this.getEvent(this.currentEventID)
        .then((resp) => {
          this.event = resp;
          this.isEventLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isEventLoading = false;
        });
    },
  },
};
</script>
