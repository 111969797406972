<template>
  <div>
    <Preloader :loading="isWorkshopsLoading" />
    <section v-if="!isWorkshopsLoading" class="workshops padding-x">
      <Heading type="h2" text-align="center" class="workshops__heading">
        Workshops
      </Heading>

      <template v-if="workshops && workshops.length">
        <div
          class="workshops__item"
          v-for="workshop in workshops"
          :key="workshop.id"
          @click="
            $router.push({
              name: 'Workshop',
              params: {
                id: currentEventID || $route.params.id,
                workshop: workshop.id,
              },
            })
          "
        >
          <div
            class="workshops__item-img"
            :style="`background-image: url(${
              workshop.primary_image
                ? workshop.primary_image
                : require('../../assets/img/village-sample.jpg')
            } )`"
          ></div>
          <div>
            <div class="workshops__item-title">{{ workshop.title }}</div>
            <div class="workshops__item-instructor">
              Instructor{{ workshop.instructor.length > 1 ? "s" : null }}:
              <span
                v-for="(guy, index) in workshop.instructor"
                :key="guy.instructor + index"
              >
                <span v-if="index !== 0">, </span>
                {{ guy.instructor }}
              </span>
            </div>
            <div class="workshops__item-time my-2">
              <img
                src="../../assets/img/icons/calendar.svg"
                alt="sessions icon"
                class="mr-2"
              />
              {{ getWorkshopDay(workshop.start) }}
            </div>
            <div
              v-html="workshop.description"
              class="workshops__item-description"
            />
          </div>
        </div>
      </template>
      <UpcomingWorkshops v-else :eventId="currentEventID" />
    </section>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import UpcomingWorkshops from "@/components/event/upcoming/Workshops";
import { mapActions, mapState } from "vuex";

export default {
  name: "Workshops",
  props: {},
  components: {
    UpcomingWorkshops,
    Preloader,
    Heading,
  },
  data: () => ({
    workshops: [],
    isWorkshopsLoading: false,
  }),
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    this.isWorkshopsLoading = true;
    this.getWorkshopsList();
  },
  methods: {
    ...mapActions(["GET"]),
    getWorkshopsList() {
      return this.GET({
        route: `/public/workshop?tag=${this.currentEventID}`,
      })
        .then((resp) => {
          this.isWorkshopsLoading = false;
          this.workshops = resp.data;
        })
        .catch((err) => {
          console.log(err);
          this.isWorkshopsLoading = false;
        });
    },
    getWorkshopDay(day) {
      const startDate = new Date(day);
      return startDate.toLocaleString("en-US", {
        day: "2-digit",
        month: "long",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
.workshops {
  padding: 50px 0;

  &__heading {
    margin-bottom: 17px;
  }

  &__item {
    cursor: pointer;
    letter-spacing: 0.05em;
    margin-bottom: 13px;

    &-img {
      width: 100%;
      height: 214px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &-title {
      font-family: $sarabun;
      font-weight: $sansBold;
      font-size: 24px;
      letter-spacing: 0.08em;
      /*color: black;*/
    }
    &-instructor {
      /*      color: $dark-gray;*/
      font-weight: $sansBold;
    }
    &-block {
      /*      background: $light-gray;*/
    }

    &-time {
      display: flex;
      /*      color: $dark-gray;*/
      font-weight: $sansBold;
    }
  }

  @media (min-width: $media-sm) {
    &__heading {
      margin-bottom: 50px;
    }

    &__item {
      display: flex;

      &-img {
        width: 208px;
        height: 286px;
        min-height: 286px;
        min-width: 208px;
        margin-right: 3%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  @media (min-width: $media-md) {
    &__item {
      margin-bottom: 24px;
      &-title {
        margin: 20px 0;
      }
      &-img {
        min-width: 296px;
        min-height: 408px;
        width: 296px;
        height: 408px;
      }
    }
  }
  @media (min-width: $media-lg) {
    padding: 120px 0 20px;

    &__heading {
      margin-bottom: 82px;
    }

    &__item {
      margin-bottom: 30px;
      &-img {
        min-width: 368px;
        min-height: 504px;
        width: 368px;
        height: 504px;
      }
    }
  }
}
</style>
