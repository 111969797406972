<template>
  <div class="hero padding-x" :style="`background-image: url(${mainImage});`">
    <Preloader
      v-if="loading"
      :loading="loading"
      size="50px"
      class="hero__loader"
    />
    <div v-show="!loading && !isEmpty(event)" class="hero__wrapper">
      <div class="hero__info">
        <div class="hero__head">
          <div class="hero__headings-wrapper">
            <Heading type="h1" class="mt-2 hero__heading hero__title">
              Exploit Conferences
            </Heading>
            <p class="hero__text">Upcoming conference:</p>
            <Heading type="h1" class="mt-2 hero__heading">
              {{ event?.name }}
            </Heading>
          </div>
          <div v-if="event?.start" class="hero__timer">
            Event coming in
            <Timer :start-time="event?.start" />
            <!--            <Button class="mt-12" @click="$router.push(`/${event.id}/join`)">
              REGISTER NOW
            </Button>-->
          </div>
        </div>
        <div class="hero__box-wrapper">
          <div class="hero__box">
            <h5 class="hero__box-heading">LOCATION</h5>
            <div class="hero__box-text">
              {{ event?.venue?.name }}
              <div>{{ event?.venue?.address }}</div>
            </div>
          </div>
          <div class="hero__box">
            <h5 class="hero__box-heading">Date</h5>
            <div class="hero__box-text">
              {{ confDay }}
              <div>{{ confTime }} {{ event?.tz_abbrev }}</div>
            </div>
          </div>
        </div>

        <div class="d-flex">
          <img
            src="../../assets/img/icons/circle-arrow-right.svg"
            alt="arrow"
            width="32"
            class="mr-3"
          />
          <Link :link="`/${event.id}`"> Get More Info</Link>
        </div>
      </div>
    </div>
    <div v-if="isEmpty(event)" class="no-event">
      <img src="@/assets/img/logo.png" alt="ArcticCon logo" class="mb-10" />
      Events are coming soon
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
// import Button from "@/components/common/Button";
import Preloader from "@/components/common/Preloader";
import Link from "@/components/common/Link";
import Timer from "../common/Timer";
import { isEmpty } from "@/assets/js/utils";

export default {
  name: "MainBlock",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Timer,
    Preloader,
    Heading,
    // Button,
    Link,
  },
  data: () => ({
    timeLeft: null,
    timeLeftWord: "days",
  }),
  computed: {
    mainImage() {
      return require("@/assets/img/main_background.jpg");
    },
    // mainImage() {
    //   return this.event.background
    //     ? this.event.background
    //     : require("@/assets/img/main_background.png");
    // },
    startTimeHours() {
      return this.getTime(this.event.start, "time");
    },
    endTimeHours() {
      return this.getTime(this.event.end, "time");
    },
    confDay() {
      return this.getTime(this.event.start, "day");
    },
    confTime() {
      return this.event?.start && this.event?.end
        ? `${this.startTimeHours} - ${this.endTimeHours}`
        : "";
    },
  },
  methods: {
    isEmpty,
    getTime(dateString, modifyer) {
      const startDate = new Date(dateString);
      const options =
        modifyer === "time"
          ? {
              timeZone: this.event.time_zone,
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
            }
          : {
              timeZone: this.event.time_zone,
              month: "short",
              day: "2-digit",
              year: "numeric",
            };
      return startDate.toLocaleString("en-US", options);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.hero {
  $self: &;

  height: 100vh;
  position: relative;
  padding-top: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  /*  -webkit-filter: grayscale(100%); !* Safari 6.0 - 9.0 *!
                    filter: grayscale(100%);*/
  overflow: hidden;
  padding-bottom: 5%;

  /*  &::after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100vw;
                      height: 100vh;
                      background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
                    }*/

  &__loader {
    margin-top: 50%;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    font-weight: bold;
  }

  &__timer {
    padding-top: 10%;
    font-size: 24px;
    letter-spacing: 0.05em;

    .card-session-timer {
      text-align: left;
    }
  }

  &__heading {
    position: relative;
    overflow-wrap: break-word;
    font-size: 40px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  &__title {
    color: $red;
  }

  &__box-wrapper {
    display: flex;
  }

  &__box {
    background-color: rgba(0, 0, 0, 0.5);
    height: 204px;
    padding: 24px 10% 24px 24px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;

    &:first-of-type {
      margin-right: 32px;
    }

    &-heading {
      font-family: $Oswald;
      font-size: 18px;
      opacity: 0.5;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    &-text {
      font-family: $Oswald;
      font-size: 18px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      div {
        margin-top: 12px;
        font-family: $Lato;
        font-size: 0.5em;
        line-height: 24px;
        letter-spacing: 0.05em;
        opacity: 0.5;
      }
    }
  }

  @media (min-width: $media-xs) {
    padding-top: 95px;
  }

  @media (min-width: $media-sm) {
    padding-top: 70px;
    /*padding-top: 138px;*/

    &__wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }

    &__heading {
      text-align: left;
    }

    &__box {
      /*&:first-of-type {*/
      /*  margin-bottom: 10%;*/
      /*}*/
    }
    &__box-heading {
      font-size: 24px;
      line-height: 24px;
    }
    &__box-text {
      white-space: unset;
      overflow: unset;
    }
  }

  @media (min-width: $media-md) {
    &__loader {
      margin-top: 15%;
    }

    &__headings-wrapper {
      width: 40%;
    }

    &__heading {
      font-size: 2em;
    }

    &__head {
      flex-direction: row;
    }

    &__title {
      font-size: 62px;
      /*line-height: 107px;*/
    }

    &__timer {
      text-align: end;

      button {
        margin-left: auto;
      }
    }
  }

  @media (min-width: $media-lg) {
    &__headings-wrapper {
      width: 70%;
      /*width: 50%;*/
    }

    &__box-heading {
      font-size: 26px;
      line-height: 26px;
    }
    &__box-text {
      font-size: 32px;
      line-height: 40px;
    }
    &__timer {
      width: 386px;
      /*width: 540px;*/
      .card-session-timer {
        /*font-size: 72px;*/
        font-size: 2em;
        /*line-height: 107px;*/
        white-space: nowrap;
      }
    }
  }
}

.no-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 3rem;
  font-family: $Oswald;
  letter-spacing: 0.05em;
  color: $red;
}
</style>
