<template>
  <div>
    <Preloader :loading="isVillagesLoading" />
    <section
      v-if="villages && villages.length && !isVillagesLoading"
      class="villages padding-x"
    >
      <Heading type="h2" text-align="center" class="villages__heading">
        Villages
      </Heading>
      <Carousel wrap-around items-to-show="3">
        <Slide v-for="village in villages" :key="village.id">
          <VillageCard
            :data="village"
            :image="village.img"
            has-description
            @click="$router.push(`/${$route.params.id}/village/${village.id}`)"
            class="villages__item"
          />
        </Slide>
        <template #addons>
          <Navigation v-if="villages.length > 3" />
        </template>
      </Carousel>
    </section>
    <!-- <ComingSoon v-else entity="Villages" class="mt-16" /> -->
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
// import ComingSoon from "@/components/common/ComingSoon";
import VillageCard from "@/components/common/CommonCard";
import { mapActions } from "vuex";

export default {
  name: "Villages",
  props: {},
  components: {
    Preloader,
    Heading,
    // ComingSoon,
    VillageCard,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    villages: [],
    isVillagesLoading: false,
  }),
  mounted() {
    this.isVillagesLoading = true;
    this.getVillages();
  },
  methods: {
    ...mapActions(["GET"]),
    getVillages() {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/village/`,
      })
        .then((resp) => {
          this.isVillagesLoading = false;
          this.villages = resp.data;
        })
        .catch((err) => {
          console.log(err);
          this.isVillagesLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.villages {
  padding-top: 32px;
  padding-bottom: 162px;
  background-color: $lighter-gray;

  &__heading {
    margin-bottom: 16px;
  }

  &__item {
    cursor: pointer;
  }

  @media (min-width: $media-xs) {
    &__item {
      padding: 0 8px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 120px;

    &__heading {
      margin-bottom: 52px;
    }
  }

  @media (min-width: $media-lg) {
    &__heading {
      margin-bottom: 82px;
    }
  }
}
</style>
