<template>
  <div class="preloader-wrapper" :class="{ 'no-padding': !loading }">
    <grid-loader :loading="loading" :color="color" :size="size"></grid-loader>
  </div>
</template>

<script>
import GridLoader from "vue-spinner/src/GridLoader.vue";

export default {
  name: "Preloader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: String,
      default: "20px",
    },
  },
  components: {
    GridLoader,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.preloader-wrapper {
  padding: 40px 10px;
  display: flex;
  justify-content: center;
}

.no-padding {
  padding: 0;
}
</style>
