<template>
  <Preloader
    v-if="isEventLoading"
    :loading="isEventLoading"
    size="50px"
    class="loader"
  />
  <Container v-else>
    <router-view></router-view>
  </Container>
</template>

<script>
import { mapActions } from "vuex";
import Container from "@/components/container/Container";
import Preloader from "@/components/common/Preloader";

export default {
  name: "App",
  components: { Container, Preloader },
  data() {
    return {
      isEventLoading: false,
      // currentId: "",
    };
  },
  computed: {},
  created() {
    this.getNearestEvent();
  },
  // watch: {
  //   "$router.params": {
  //     handler(value) {
  //       if (value && value.includes("access_token")) {
  //       }
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapActions(["GET"]),
    getNearestEvent() {
      this.isEventLoading = true;
      return this.GET({ route: "/public/event/upcoming_events/" })
        .then(({ data }) => {
          if (data) {
            const diffDate = Date.now();
            const sortedEvents = data.sort(function (a, b) {
              return (
                Math.abs(diffDate - a.start) - Math.abs(diffDate - b.start)
              );
            });
            this.$store.commit("setCurrentEventID", sortedEvents[0].id);
            if (this.$route.name !== "Ticket") {
              this.$store.commit("setCurrentEventDetails", sortedEvents[0]);
            }
            this.$store.commit("setNextEventDetails", sortedEvents[0]);
            this.$store.commit("setUpcomingEvents", sortedEvents);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("setCurrentEventID", "");
          this.$store.commit("resetEventState");
        })
        .finally(() => {
          this.isEventLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables";

.loader {
  margin-top: 50%;
}

@media (min-width: $media-md) {
  .loader {
    margin-top: 15%;
  }
}
</style>
