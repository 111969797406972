<template>
  <button
    class="button"
    v-bind="$attrs"
    :disabled="disabled || loading"
    :loading="loading"
    :class="{
      'button-full-width': fullWidth,
      'button-outlined': outlined,
    }"
  >
    <slot v-if="!loading" />
    <clip-loader :loading="loading" color="white" />
  </button>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "Button",
  inheritAttrs: false,
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { ClipLoader },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.button {
  display: block;
  padding: 12px 32px;
  border-radius: 10px;
  background-color: $red;
  color: $white;
  font-family: $Oswald;
  font-size: 1.3rem;
  line-height: 1;
  /*font-size: 24px;*/
  /*line-height: 36px;*/
  letter-spacing: 0.05em;
  text-transform: uppercase;
  height: 56px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s;

  &:hover {
    background-color: transparent;
    color: $red;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      background-color: initial;
      color: initial;
    }
  }

  &-full-width {
    width: 100%;
  }

  &-outlined {
    background-color: transparent;
    color: $red;
    border: 2px solid $red;
  }

  &-outlined:hover {
    background-color: $red;
    color: $white;
  }

  @media (min-width: $media-sm) {
    height: 60px;
  }
}
</style>
